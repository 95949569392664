const gaProperty = 'UA-152813579-1'

// see: https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable
const disableStr = 'ga-disable-' + gaProperty
function gaDisabled () {
  return document.cookie.indexOf(disableStr + '=true') > -1
}
function gaOptout() {
  document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'
  location.reload()
}
window.addEventListener('load', function () {
  const gaStatus = document.getElementById('ga-status')
  if (gaStatus) gaStatus.innerText = gaDisabled() ? 'deaktiviert' : 'aktiv'
})

// ga script
window.dataLayer = window.dataLayer || []
function gtag (){ dataLayer.push(arguments) }
gtag('js', new Date())
gtag('config', gaProperty)
gtag('config', gaProperty, { 'anonymize_ip': true })
